/* colors */
:root {
  --text-color-dark: #333629;
  --text-color-blue: #53096e;
  --text-color-light: #81876d;
  --text-color-light-blue: #815390;
  --body-color: #ffffff;
  --file-uploadColor: #dfdfdf;
  --light-green-color: #57c042;
}

:root {
  --popping-font-medium: "Poppins", "sans-serif";
  --open-sans-regular: "Open Sans", "sans-serif";
}
.border-dark {
  border-color: var(--text-color-dark);
}
.text-light-color {
  color: var(--light-green-color);
}
.ulicon {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.liicon {
  padding-left: 1em;
  text-indent: -1em;
}

.liicon-before:before {
  content: ">";
  padding-right: 5px;
}

.footer-background-color {
  background-color: #f6f8f5;
}
.font-family-poppins {
  font-family: var(--popping-font-medium);
}
.font-family-opensans {
  font-family: var(--open-sans-regular);
}
.bg-primary-light-blue {
  background-color: var(--text-color-light-blue);
}
.text-color-gray-dark {
  color: var(--text-color-dark) !important;
}
.text-primary-light-blue {
  color: var(--text-color-light-blue) !important;
}
.text-color-secondary-dark-blue {
  color: var(--text-color-blue) !important;
}
.bg-file-upload-color {
  background-color: var(--file-uploadColor) !important;
}
body {
  background-color: var(--body-color) !important;
}
.mt-6 {
  margin-top: 4em;
}
.mb-6 {
  margin-top: 4em;
}
.fs-7 {
  font-size: 0.75rem !important;
}
.app-primary-btn {
  border: 1px solid var(--text-color-light-blue);
  border-radius: 50px;
  box-shadow: -3.032px 10.574px 16px 0px rgba(0, 0, 0, 0.15);
  font-family: var(--popping-font-medium);
  font-weight: 500;
}
.link-blue-hover:hover {
  color: var(--text-color-blue) !important;
}
.app-primary-btn:hover {
  box-shadow: 5.032px 3.574px 16px 0px rgba(0, 0, 0, 0.15);
  color: var(--text-color-light-blue) !important;
}
.app-dark-color {
  color: var(--text-color-dark);
}

.hover-btn:hover {
  box-shadow: 5.032px 3.574px 16px 0px rgba(0, 0, 0, 0.15) !important;
}

.hover-btb-underline:hover {
  text-decoration: underline;
}
.user-navbar {
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.15);
}

.background-gradient {
  background-image: linear-gradient(
    var(--text-color-blue),
    var(--text-color-light-blue)
  );
}
.background-gradient-2 {
  background-image: linear-gradient(#4596dd, #5ddf84);
}
.line {
  position: absolute;
  background: #4b4f3e;
  height: 1px;
  width: inherit;
  z-index: -1;
  top: 12.5%;
  left: 0;
  right: 0;
}
.cursor-pointer {
  cursor: pointer;
}
.block-pointer {
  cursor: no-drop;
}
.file-upload-container {
  border: 2px dashed #aaaaaa;
  padding: 20px;
  text-align: center;
}

.file-upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.icon-upload {
  font-size: 40px;
  color: #333629;
}

.file-upload-content p {
  margin: 10px 0;
}

.file-upload-content span {
  font-weight: bold;
  color: #333629;
}

.file-upload-container:hover {
  border-color: #8c995f;
}
.file-outer-container {
  background-color: #f4f4f4;
  padding: 0.5em;
}

.error-message {
  color: red !important;
  font-weight: 200 !important;
  font-family: var(--open-sans-regular);
}

.descriptionMain {
  color: var(--text-color-blue);
  font-weight: 500;
}
.text-justify {
  text-align: justify;
}
.processBlock {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  width: 50px;
}

.processBlockSubtitle {
  font-size: 16px;
}

.faqs-accordian .accordion-button::after {
  background-image: url("../images/icons/accordian-on.png");
  background-color: var(--text-color-light-blue);
}

.faqs-accordian .accordion-button.collapsed::after {
  background-image: url("../images/icons/accordian-off.png");
  background-color: var(--text-color-light-blue);
}
.customButton {
  font-size: 16px;
  cursor: pointer;
  box-shadow: -3.032px 10.574px 16px 0px rgba(183, 90, 90, 0.15);
  padding: 8px 28px;
  border: none;
}

.banner-with-backgroundImage {
  background: url(../images/banner.jpg) no-repeat;
  background-size: cover;
}

.banner-section {
  padding: 80px 0;
  position: relative;
  margin: 50px 0;
}
.draganddrop {
  flex-direction: row;
}
.banner-section .container {
  z-index: 3;
  position: relative;
}
.banner-with-gradient:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: linear-gradient(2deg, #c3c7b6 0%, #99ae60 100%);
  opacity: 0.9;
}
.testimonail-container {
  padding: 40px 20px 20px;
}
.registerwidth {
  width: 50%;
}
.footerMobile {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.testimonailImage {
  width: 120px;
  height: 120px;
  max-width: 100%;
}
.form-focus:focus {
  border-color: var(--text-color-light-blue);
}
.respheight {
  height: 700px;
}

.respheight3 {
  height: 700px;
}
.respheight2 {
  height: 500px;
}
.height-100 {
  height: 100vh;
}
.reg-container {
  margin-top: 5em;
}

.reg-container .form-control {
  padding: 0.75em;
}
.spinnerContainer {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background-color: rgba(8, 0, 0, 0.5);
}
.terms-align-main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-evenly;
}
@media screen and (max-width: 786px) {
  .line {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .reg-container {
    width: 100% !important;
  }
}
.contact-us-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
}
.containcss {
  min-height: 650px;
}
.formContact {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 100%;
  width: 50%;
}
.mt-10 {
  margin-top: 6em;
}

.contact-us-main-colour {
  color: #333629;
}
.link-color {
  color: rgb(93, 130, 143);
}
.footeralign {
  display: flex;
  flex-direction: row;
}
.width40vw {
  width: 40vw;
}
.j-c-evenly {
  justify-content: space-evenly;
}
@media screen and (max-width: 1059px) {
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 90%;
  }
  .formContact {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    height: 100%;
    width: 90%;
  }
}
@media screen and (max-width: 1400px) {
  .respheight {
    height: 1000px;
  }
  .respheight3 {
    height: 1000px;
  }
  .respheight2 {
    height: 600px;
  }
  .draganddrop {
    flex-direction: column;
    height: 800px;
  }
  .widthvw {
    width: 80vw !important;
    height: 100% !important;
  }
}
@media screen and (max-width: 770px) {
  .draganddrop2 {
    width: 80% !important;
  }
}
@media screen and (max-width: 600px) {
  .draganddrop2 {
    width: 80vw !important;
  }
}
@media screen and (max-width: 1250px) {
  .width40vw {
    width: 80vw;
  }
}

@media screen and (max-width: 770px) {
  .hammad {
    width: 100px !important;
    height: 100px !important;
  }
  .widthres80 {
    min-width: "630px";
    margin-top: "10vh";
  }
}

@media screen and (max-width: 770px) {
  .footerMobile {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .registerwidth {
    width: 90%;
  }
  .wregister {
    height: 30vh !important;
  }
  .footerdownload{
    display:none;
  }
    
}
