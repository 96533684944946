/* ==============================  */
.fs-7 {
  font-size: 0.75rem !important;
}
.text-color-secondary-dark-blue {
  color: var(--text-color-blue) !important;
}
.font-family-poppins {
  font-family: var(--popping-font-medium);
}
.font-family-opensans {
  font-family: var(--open-sans-regular);
}
.hover-btn:hover {
  box-shadow: 5.032px 3.574px 16px 0px rgba(0, 0, 0, 0.15) !important;
}
.bg-primary-light-blue {
  background-color: var(--text-color-light-blue);
}
.form-focus:focus {
  border-color: var(--text-color-light-blue);
}
.footer-background-color {
  background-color: #f6f8f5;
}
.login-side {
  height: 100vh;
}
.height-vh {
  height: 100vh;
}

@media screen and (max-width: 808px) {
  .login-side {
    height: 100vh;
    width: 100vw;
  }
  .login-width {
    width: 80vw;
  }
  .animated-section {
    height: 0vh;
    width: 0vw;
  }
  .height-vh {
    height: 30vh;
  }
  .height-vh2 {
    height: 70vh;
  }
}
@media screen and (max-width: 808px) {
  .displaytonone {
    display: none;
  }
  .login-side {
    height: 100vh;
    width: 100vw;
  }
  .login-width {
    width: 80vw;
  }
  .animated-section {
    height: 0vh;
    width: 0vw;
  }
  .height-vh {
    height: 30vh;
  }
  .height-vh2 {
    height: 70vh;
  }
}
@media screen and (max-height: 667px) {
  .displaytonone {
    display: none;
  }
  .login-side {
    height: 100vh;
    width: 100vw;
  }
  .login-width {
    width: 80vw;
  }
  .animated-section {
    height: 0vh;
    width: 0vw;
  }
  .height-vh {
    height: 25vh;
  }
  .height-vh2 {
    height: 75vh;
  }
}
